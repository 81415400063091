import React, { useCallback } from "react";
import { useGetOrganisationContext } from "../../providers/organisations";

function SearchForm(props) {
  const { search, doUserSearch } = useGetOrganisationContext();
  // const  history = useHistory();

  const doSubmit = useCallback(
    (event) => {
      event.preventDefault();
      doUserSearch(event?.target?.input?.value);
    },
    [doUserSearch]
  );

  const doSearch = useCallback(
    (event) => {
      event.preventDefault();
      doUserSearch(event.target.value);
      // history.replace(APP_ROUTES.HOME);
    },
    [doUserSearch]
  );

  return (
    <form onSubmit={doSubmit} className="form">
      <div className="search-form">
        <input
          id="input"
          name="input"
          type="text"
          placeholder="Search"
          value={search}
          onChange={doSearch}
        />
        <div className="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="#ccc"
              strokeWidth="4"
              className="opacity-25"
            ></circle>
            <path
              fill="#000"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              className="opacity-75"
            ></path>
          </svg>
        </div>
      </div>
    </form>
  );
}

export default SearchForm;
