import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { fetchOrganisations } from "../api";

const OrganisationContext = createContext({});

export function useGetOrganisationContext() {
  return useContext(OrganisationContext);
}

const VARSOBJ = {
  men: "male",
  women: "female",
  lgbtqia: "gay",
};
function OrganisationsProvider(props) {
  const [data, setData] = useState();
  const [searchReg, setSearchReg] = useState(null);
  const [search, setSearch] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    const elKey = Object.keys(VARSOBJ).find(
      (key) => new RegExp(`\\b(${VARSOBJ[key]})\\b`, 'gmi').exec(pathname)?.length
    );

    if (elKey) {
      setSearch(elKey);
      setSearchReg(new RegExp(`\\b(${elKey})\\b`, "gmi"));
    }
  }, [pathname]);

  useEffect(() => {
    fetchOrganisations().then((data) => {
      const sortedData = data.sort((a, b) =>
        a.post_title.localeCompare(b.post_title)
      );
      setData(sortedData);
    });
  }, []);

  const doUserSearch = useCallback((name) => {
    setSearch(name);
    setSearchReg(null);
  }, []);

  const filteredData = data?.filter((e) => {

    if (searchReg) {
      const { post_title, post_content, post_excerpt, search_tag } = e;
      const fields = [post_title, post_content, post_excerpt, search_tag];

      return fields.some((field) => {
        if (searchReg.exec(field?.toLowerCase?.())?.length) {
          return true;
        }

        return false;
      });
    }

    if (typeof search === "string") {
      if (!search) {
        return true;
      }

      const searchText = search.toLowerCase();
      const { post_title, post_content, post_excerpt, search_tag } = e;
      const fields = [post_title, post_content, post_excerpt, search_tag];

      return fields.some((field) => {
        if (field) {
          // Create a regular expression pattern to match the exact word searchText
          const pattern = new RegExp(`\\b${searchText}\\b`, 'i');
          // Check if searchText contains "lgbt" or "bay" and return partial matches
          if (searchText.includes("lgbt") || searchText.includes("gay")) {
            return pattern.test(field.toLowerCase()) || field.toLowerCase().includes('lgbt');
          } else {
            return pattern.test(field.toLowerCase());
          }
        }
        return false;
      });
    }

    // Handle the case when neither of the conditions is met
    return false;
  });

  return (
    <OrganisationContext.Provider
      value={{ data: filteredData, allData: data, doUserSearch, search }}
    >
      {props.children}
    </OrganisationContext.Provider>
  );
}

export default OrganisationsProvider;
