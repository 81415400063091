import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../constants/routes";
import { generatePath } from "../../lib/react-router-dom";

function Button(props) {

    return (
        <button mat-raised-button="" color="primary" className="back-button mat-focus-indicator mat-raised-button mat-button-base mat-primary" style={{marginTop: 10}} tabIndex="0">
            <span className="mat-button-wrapper"><Link to={generatePath(APP_ROUTES.HOME)}>Back</Link></span><span matripple="" className="mat-ripple mat-button-ripple"></span>
            <span className="mat-button-focus-overlay"></span>
        </button>
    );

}

export default Button;
