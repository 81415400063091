import "./App.css";
import { Switch, Route, useParams } from "react-router-dom";

import SearchForm from "./components/common/SearchForm";
import OrganisationsList from "./components/organisation/OrganisationsList";
import OrganisationsMap from "./components/organisation/OrganisationsMap";
import OrganisationDetail from "./components/organisation/OrganisationDetail";
import OrganisationContact from "./components/organisation/OrganisationContact";
import Button from "./components/shared/Button";
import { useGetOrganisationContext } from "./providers/organisations";
import { APP_ROUTES } from "./constants/routes";

function AppWrapper(props = {}) {
  return (
    <div className="container container--full" id="primary">
      <div className="row row--flex">
        <div className="col col--lg-6 col--md-12 col--sm-12 col--xs-12 col1">
          {props.leftSide}
        </div>

        <div
          className="col2 col col--lg-6 col--md-12 col--sm-12 col--xs-12 align-text-center"
          style={{ padding: 0 }}
        >
          {props.rightSide}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Switch>
      <Route
        path={APP_ROUTES.DETAIL}
        component={(props) => <Detail {...props} />}
      />
      <Route
        path={[
          APP_ROUTES.HOME,
          APP_ROUTES.MALE,
          APP_ROUTES.FEMALE,
          APP_ROUTES.GAY,
        ]}
        component={(props) => <Home {...props} />}
      />
    </Switch>
  );
}

export default App;

function Home() {
  const a = useGetOrganisationContext();

  return (
    <AppWrapper
      leftSide={
        <div className="p-base h-full">
          <SearchForm></SearchForm>

          <OrganisationsList organisations={a.data}></OrganisationsList>
        </div>
      }
      rightSide={<OrganisationsMap organisations={a.data}></OrganisationsMap>}
    ></AppWrapper>
  );
}

function Detail() {
  const a = useGetOrganisationContext();

  return (
    <AppWrapper
      leftSide={
        <>
          <OrganisationDetail organisations={a.allData}></OrganisationDetail>
        </>
      }
      rightSide={
        <>
          <Button>Back</Button>
          <OrganisationContact organisations={a.allData}></OrganisationContact>
        </>
      }
    ></AppWrapper>
  );
}
