import React from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../constants/routes";
import { generatePath } from "../../lib/react-router-dom";

function OrganisationListDetail(props) {
  return (
    <li>
      <Link
        to={generatePath(APP_ROUTES.DETAIL, {
          id: props.organisation.ID,
        })}
      >
        <span className="block uppercase strong heading">
          {props.organisation.post_title}
        </span>
        <span>
          {props.organisation.post_excerpt.length > 80
            ? props.organisation.post_excerpt.substring(0, 80) + "..."
            : props.organisation.post_excerpt}
        </span>
      </Link>
    </li>
  );
}
export default OrganisationListDetail;
