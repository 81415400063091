import React from 'react';
import { useParams } from 'react-router-dom';
import LoaderSpinner from '../common/Loader';

function OrganisationDetail(props) {
    const { organisations: organisationsData = [] } = props;
    const { id } = useParams();
    const organisation = organisationsData.find(org => org.ID === Number(id));

    if(!organisationsData?.length){
        return <LoaderSpinner/>;
    }
    if (!organisation) {
        return <div>organisation not found</div>;
    }

    const location = organisation.location.city ? `${organisation.location.city}, ${organisation.location.country}` : organisation.location.country;

    const cost = organisation?.organisation_cost?.label ?? 'No Fee';

    console.log(cost, organisation.post_title, location, organisation.post_content, organisation.post_excerpt)

    return (
        <div className="space__content">
            <div className="max-width-content">
                <div className="space__content__content">
                    {organisation.post_title ? <h1 className="strong">{organisation.post_title}</h1> : null}
                    {location ? <p className="italic">{location}</p> : null}
                    {organisation.post_content ? <p style={{paddingRight: 10}}>{organisation.post_content}</p> : null}
                    {organisation.post_excerpt ? <p style={{paddingRight: 10}} className="strong">Services for: {organisation.post_excerpt}</p> : null}
                    {cost ? <p className="strong">Cost: {cost}</p> : null}
                </div>
            </div>
        </div>
    );
}

export default OrganisationDetail;
