import React from "react";
import OrganisationListDetail from "./OrganisationListDetail";

function OrganisationsList(props) {
  const { organisations: organisationsData = [] } = props;

  return (
    <ul className="list list--spaces">
      {organisationsData.map((organisation) => (
        <OrganisationListDetail
          key={organisation.ID}
          organisation={organisation}
        />
      ))}
    </ul>
  );
}

export default OrganisationsList;
