import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../constants/routes";
import { generatePath } from "../../lib/react-router-dom";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useGetOrganisationContext } from '../../providers/organisations';

function OrganisationsMap(props) {

    return (
        <div id="map" style={{overflow: 'hidden', position: 'relative'}}>
            <MapComponent/>
        </div>
    );

}

function MapComponent(props) {
    const {
        center = {
            lat: 53.4096252,
            lng: -2.2320648
        }
    }= props;

    const {data = []} = useGetOrganisationContext();

    const markers = data.map((el) => (
        {
            id: el.ID,
            name: el.post_title,
            country: el.country,
            logo: el.logo,
            position: { lat: parseFloat(el.location.lat), lng: parseFloat(el.location.lng) }
        }
    ));

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDlYRfFU1xkbrkQZl6e1oTjxDh6t1Vtnnc"
    })

    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
          return;
        }
        setActiveMarker(marker);
    };
  
    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(({ position }) => bounds.extend(position));
    };
  
    const onUnmount = React.useCallback(function callback(map) {
        // setMap(null)
    }, [])
  
    return isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top:0,
            left:0,
            backgroundColor: '#e5e3df'
          }}
          center={center}
          zoom={7}
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          onUnmount={onUnmount}
        >
          { /* Child components, such as markers, info windows, etc. */ }
            {markers.map(({ id, name, country, logo, position }) => (
                <Marker
                key={id}
                position={position}
                onClick={() => handleActiveMarker(id)}
                >
                {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div className='google-map-infobox'>
                            {logo ? <p><img src={ logo} alt={name} width='100' height='auto' /></p> : null}
                            <h3 className='firstHeading'>{name}</h3>
                            <p>{country}</p>
                            <Link
                                to={generatePath(APP_ROUTES.DETAIL, {
                                    id: id,
                                })}
                            >Read More</Link>
                        </div>
                    </InfoWindow>
                ) : null}
                </Marker>
            ))}
        </GoogleMap>
    ) : <></>
}
  

export default OrganisationsMap;
