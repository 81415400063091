import React from 'react';
import { useParams } from 'react-router-dom';
import LoaderSpinner from '../common/Loader';

function OrganisationContact(props){
    const { organisations: organisationsData = [] } = props;
    const { id } = useParams();
    const organisation = organisationsData.find(org => org.ID === Number(id));

    if(!organisationsData?.length){
        return <LoaderSpinner/>;
    }

    if (!organisation) {
        return <div>organisation not found</div>;
    }

    return (
        <div className="space__contact">
            <div>
                {organisation.logo ? <img width={269} src={organisation.logo} className="attachment-place-logo size-place-logo" alt={organisation.post_title} loading="lazy" sizes="(max-width: 269px) 100vw, 269px" /> : null}

                {organisation.website ? <p className="flush"><a href={organisation.website} target="_blank" rel="noreferrer">{organisation.website}</a></p> : null}
                {organisation.email ? <p className="flush"><a href="mailto:{organisation.email}">{organisation.email}</a></p> : null}

                {organisation.social_media.length > 0 && (
                    <ul className="list list--social">
                        {organisation.social_media.map(item => (
                            <li key={item.ys_social_media_name}>
                                <a href={item.ys_social_media_link} target="_blank" rel="noreferrer">
                                    <img src={item.ys_social_media_icon} alt={item.ys_social_media_name} />
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
       </div>
    );

}

export default OrganisationContact;
